import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SectionProps } from '../../types/Section';
import { SidebarTitle } from '../../types/Sidebar';
import {
    KnownBreakpoints,
    THEME_BREAKPOINTS,
} from '../../../../common/constants';

const styles = {
    container: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.laptop])]: {
            display: 'none',
        },
    }),
    title: (theme: Theme, color: string) => ({
        color: color ? color : theme.palette.common.white,
        fontSize: '24px',
        fontWeight: 500,
        marginTop: '-5px',
    }),
};

const SidebarTitle: React.FunctionComponent<SectionProps> = (props) => {
    const theme = useTheme<Theme>();
    const { data: { title: { name, color }}} = props as { data: SidebarTitle };

    return (
        <Box sx={ styles.container }>
            <Typography component="h5" sx={ styles.title(theme, color) }>
                { name }
            </Typography>
        </Box>
    );
};

export default SidebarTitle;
